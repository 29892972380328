import React, { useContext, useMemo } from "react"
import styled from "styled-components/macro"
import { useLocation } from "react-router-dom"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import { getStringParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"
import { fontWeight, palette, fontFamily, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
// import { appStoreLinkFor } from "../../../../../common/game-text"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import constants from "../../../../../common/constants"

const RoadBlockWrap = styled.div`
  will-change: scroll-position;
  min-height: 26rem;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  background-color: ${palette.white};
  background-image: url(/static/sport-assets/basketball/games-block-road-bkg.svg);
  background-repeat: repeat-x;

  & > .welcome-title {
    font-family: ${fontFamily.base};
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: ${fontWeight.medium};
    line-height: 1.24rem;
    letter-spacing: 0.1em;
    text-align: center;
    color: ${palette.gray50};
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  & > div.logo {
    display: block;
    position: relative;
    height: 7.5rem;
    width: 7.5rem;
    margin: 0 auto;

    svg {
      height: auto;
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      vertical-align: top;
      margin-bottom: 0;
    }
  }

  & > div.separator {
    display: block;
    background-color: ${palette.orange};
    height: 2px;
    width: 4rem;
    margin: 1.5rem auto;
  }

  & > .open-app-info,
  & > .congrats-text {
    margin-top: 0.5rem;

    & > span {
      font-family: ${fontFamily.base};
      font-size: ${pxToRem(18)};
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 24px;
      letter-spacing: -0.1px;
      text-align: center;
      color: ${palette.gray20};
    }
  }
  & > .congrats-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & > .open-app-info {
    margin-bottom: 1.5rem;
  }
`

const GamesRoadblock = () => {
  const poolData = useContext(PoolDataContext)
  const { deviceType } = poolData || emptyObject

  const location = useLocation()
  // const url = appStoreLinkFor(gameInstanceUid)
  const showCongrats = getStringParam("showCongrats", location?.search || "") !== "false"
  const fromData = getStringParam("from", location?.search || "")
  const gameInstanceUid = getStringParam("gameType", location?.search || "") ?? ""
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)

  const url = useMemo(() => {
    if (isWomenBracket) {
      if (fromData === "join-flow") {
        return "https://cbs-sports.app.link/Ch4wIif9Twb"
      } else if (fromData === "lobby") {
        return gameInstanceUid === constants.NCAAW_MANAGER_GAME_INSTANCE_UID
          ? "https://cbs-sports.app.link/VBrEWweKQxb"
          : "https://cbs-sports.app.link/byO74qQKknb"
      }
      return "https://cbs-sports.app.link/vX9StrQJQxb"
    } else if (fromData === "join-flow") {
      return "https://cbs-sports.app.link/JcFECz16Ydb"
    } else if (fromData === "lobby") {
      return "https://cbs-sports.app.link/g5R0xYJ6Ydb"
    } else {
      return "https://cbs-sports.app.link/PppGZzP6Ydb"
    }
  }, [fromData])

  return (
    <RoadBlockWrap>
      <AnalyticScreen feature="brackets" subfeature={`road-block`} title={`Road Block`} deviceType={deviceType} />
      <span className="welcome-title">WELCOME TO THE MADNESS</span>
      <div className="logo">
        <BracketSvg />
      </div>
      {showCongrats && (
        <div className="congrats-text">
          <span>Congratulations!</span>
          <span>You have joined the pool.</span>
        </div>
      )}
      <div className="separator" />
      <div className="open-app-info">
        <span>Play on the CBS Sports App!</span>
      </div>
      <StyledButton className="is-full-width--true variant--primary" as="a" href={url}>
        Open The App
      </StyledButton>
    </RoadBlockWrap>
  )
}

export default GamesRoadblock
